import PermissionChecker from './../auth/permissionChecker';
import React from 'react';
import {Redirect, Route, useLocation} from "react-router-dom";

function PrivateRoute({
                        component: Component,
                        currentUser,
                        permissionRequired,
                        path,
                        ...rest
                      }) {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentUser,
        );

        if (!permissionChecker.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {from: location},
              }}
            />
          );
        }
        return (
          <Component {...props} />
        );
      }}
    />
  );
}

export default PrivateRoute;
