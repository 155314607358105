import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import app from './app/appReducers';
import auth from './auth/authReducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth
  });
