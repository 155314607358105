import React, { useEffect, useState } from "react";
import SectionWrapper from "../../../components/SectionWrapper/index";
import Hand from "./../../../assets/hand.png";
import { Col, Form, Image, Input, message, Row, Button as AntButton, notification } from "antd";
import { Label } from "../../../styles";
import { Link, useHistory } from "react-router-dom";
import { FormWrapper, Navigator, PopupDetails, PopupMsg, PopupWrapper, SignupWrapper, Spacer, } from "./styles";
import Button from "../../../components/Button";
import ReactCodeInput from 'react-verification-code-input';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import authActions from "../../../modules/auth/authActions";
import authSelectors from "../../../modules/auth/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { Checkbox } from "../../../components/Checkbox/style";
import { auth } from "../../../firebase";
import appActions from "../../../modules/app/appActions";
import SideImage from "../../../assets/frame1.png";

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const isCodeSent = useSelector(
    authSelectors.selectConfirmation,
  );
  useEffect(() => {
    if (!codeSent)
      setCodeSent(isCodeSent)
  }, [isCodeSent])
  const SectionContent = () => {
    return (
      <Row>
        <Col
          xs={{ offset: 0, span: 24 }}
          md={{ offset: 12, span: 12 }}
        >
          {/*{!codeSent && <div style={{display: 'inline-flex', marginTop: '-8%', marginBottom: '-2%'}}>
            <h1 style={{fontSize: 44.5}}>
              Welcome to <b>Blog!</b>
            </h1>
            <Image className="image" preview={false} src={Hand} style={{marginLeft: 10, marginTop: 10}}/>
          </div>
          }*/}
          {codeSent &&
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{ fontSize: 44.5, fontFamily: 'Lato' }}>
              ENTER YOUR CODE
            </h1>
            <p className="para__secondary" style={{ width: "80%" }}>
              We sent you a verification code to your email. Enter it below to start your free trial!
            </p>
          </div>
          }
        </Col>
      </Row>
    );
  };
  const onFinish = async (values) => {
    const { firstName, lastName, email, password } = values;
    const name = `${firstName} ${lastName}`
    setLoading(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        await updateProfile(user, { displayName: `name` })
        user.displayName = name
        user.firstName = firstName
        user.lastName = lastName
        user.password = password
        user.source = 'email'
        setLoading(false)
        await sendEmailVerification(userCredential.user);
        dispatch(appActions.createAccount(user))
        notification.open({
          message: 'Success',
          description:
            'A verification email is sent. Please check your inbox/junk folder.',
        });
        history.push('/login')
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
      });
    
    /*const cookies = new Cookies();
    if (codeSent) {
      if (code.length !== 6) {
        message.error('Please enter a valid code');
        return;
      }
      try {
        setLoading(true)
        const confirm = await Auth.confirmSignUp(cookies.get('a_u'), code);
        if (confirm === 'SUCCESS') {
          await cookies.remove('a_u');
          await cookies.remove('codeSent');
          dispatch(authActions.updateConfirmSent(false));
          message.success('Your free trail is active now. Please login.')
          history.push("/login");
        }
      } catch (e) {
        setLoading(false)
        message.error('invalid code')
        console.log(e)
      }
      return
    }
    const {name, email, password} = values;
    try {
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
          'custom:marketing': values.marketing === true ? '1' : '0',
        },
      });
      setCodeSent(true);
      cookies.set('a_u', user.user.username, {path: '/'});
      cookies.set('codeSent', true, {path: '/'});
      dispatch(authActions.updateConfirmSent(true))
      //history.push("/login");
      message.success(
        "We have sent a verification msg to your email please verify."
      );
    } catch (error) {
      message.error(error.message);
    }*/
  };
  const handleGoogleSignup = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          console.log('result', result)
          const user = result.user;
          user.source = 'google';
          dispatch(appActions.createAccount(user))
          history.push('/requests')
        }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleChange = (e) => {
  };
  const checkConsent = (_, value) => {
    if (value) {
      return Promise.resolve();
    }
    
    return Promise.reject(new Error('You must agree to terms'));
  };
  const strengthChecker = (e) => {
    const password = e.target.value
    setPassword(password)
    if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/gms)) {
      setPasswordStrength(4)
    } else if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/gms)) {
      setPasswordStrength(3)
    } else if (password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      setPasswordStrength(2)
    } else if (password.length > 1) {
      setPasswordStrength(1)
    } else
      setPasswordStrength(0)
  }
  return (
    <div className="login" style={{ marginRight: '-2rem', marginLeft: '-2rem' }}>
      <div className="container sm:px-10">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="hidden xl:flex flex-col min-h-screen">
            <a href="" className="-intro-x flex items-center pt-5">
              <img
                alt="Midone - HTML Admin Template"
                className="w-6"
                src={require('./../../../assets/images/logo.svg').default}
              />
              <span className="text-white text-lg ml-3"> Idenfication </span>
            </a>
            <div className="my-auto">
              <img alt="Midone - HTML Admin Template" className="-intro-x w-1/2 -mt-16"
                   src={require('./../../../assets/images/illustration.svg').default}/>
              <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                A few more clicks to
                <br/>
                sign in to your account.
              </div>
              <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
              </div>
            </div>
          </div>
          
          <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
            <div
              className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                Sign Up
              </h2>
              <div className="intro-x mt-2 text-slate-400 xl:hidden text-center">A few more clicks to sign up to your
                account.
              </div>
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <div className="intro-x mt-8">
                  <Row justify={'space-between'}>
                    <Col xs={12}>
                      <Form.Item
                        name="firstName"
                        rules={[{ required: true, message: 'First name is required' }]}
                      >
                        <input type="text" className="intro-x login__input form-control py-3 px-4"
                               placeholder="First Name"/>
                      </Form.Item>
                    </Col>
                    <Col xs={11}>
                      <Form.Item
                        name="lastName"
                        rules={[{ required: true, message: 'Last Name is required' }]}
                      >
                        <input type="text" className="intro-x login__input form-control py-3 px-4"
                               placeholder="Last Name"/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                  >
                    <input type="text" className="intro-x login__input form-control py-3 px-4 block"
                           placeholder="Email"/>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (passwordStrength >= 2) {
                            return Promise.resolve();
                          }
                          return Promise.reject(<span>Password must be at-least 8 characters long <br/> and should contain letters and numbers!</span>);
                        },
                      }),
                    ]}
                  >
                    <input
                      onChange={strengthChecker}
                      type="password"
                      className="intro-x login__input form-control py-3 px-4 block"
                      placeholder="Password"/>
                  </Form.Item>
                  <div className="intro-x w-full grid grid-cols-12 gap-4 h-1 mt-3">
                    <div
                      className={`col-span-3 h-full rounded ${passwordStrength >= 1 ? 'rounded bg-success' : 'bg-slate-100 dark:bg-darkmode-800'}`}/>
                    <div
                      className={`col-span-3 h-full rounded ${passwordStrength >= 2 ? 'rounded bg-success' : 'bg-slate-100 dark:bg-darkmode-800'}`}/>
                    <div
                      className={`col-span-3 h-full rounded ${passwordStrength >= 3 ? 'rounded bg-success' : 'bg-slate-100 dark:bg-darkmode-800'}`}/>
                    <div
                      className={`col-span-3 h-full rounded ${passwordStrength >= 4 ? 'rounded bg-success' : 'bg-slate-100 dark:bg-darkmode-800'}`}/>
                  </div>
                  <a href="" className="intro-x text-slate-500 block mt-2 text-xs sm:text-sm mb-5">What is a secure
                    password?</a>
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (password === confirmPassword) {
                            return Promise.resolve();
                          }
                          return Promise.reject(<span>Password does not match.</span>);
                        },
                      }),
                    ]}
                  >
                    <input
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="password" className="intro-x login__input form-control py-3 px-4 block"
                      placeholder="Confirm Password"/>
                  </Form.Item>
                </div>
                
                <div className="intro-x flex items-center text-slate-600 dark:text-slate-500 mt-4 text-xs sm:text-sm">
                  <input id="remember-me" type="checkbox" className="form-check-input border mr-2"/>
                  <label className="cursor-pointer select-none" htmlFor="remember-me">I agree to</label>
                  <a className="text-primary dark:text-slate-200 ml-1" target="_blank" href="https://grabidentity.com/terms-and-conditions">Term & Conditions</a>
                  <label className="cursor-pointer select-none ml-1" htmlFor="remember-me">and</label>
                  <a className="text-primary dark:text-slate-200 ml-1" target="_blank" href="https://grabidentity.com/privacy">Privacy Policy</a>.
                </div>
                
                <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                  <button
                    type={'submit'}
                    className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">Register
                  </button>
                  <button
                    onClick={() => {
                      history.push('/login')
                    }}
                    className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">Login
                  </button>
                </div>
              </Form>
              <Spacer>
                <p>or</p>
              </Spacer>
              <Button
                handleClick={handleGoogleSignup}
                className="google__btn btn btn-outline-primary"
                veriant="outline"
                size="medium"
                block
              >
                Sign up with Google
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
