import React, { useState } from "react";
import { Checkbox, Form, message } from "antd";
import appActions from "../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../modules/auth/authSelectors";
import moment from "moment";
import { X } from "lucide-react";
import appSelectors from "../modules/app/appSelectors";

function NewRequest(props) {
  const {setShowModal} = props
  const dispatch = useDispatch();
  const account = useSelector(appSelectors.getAccount);
  const [isEmailUser, setEmailUser] = useState(true)
  
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(appActions.createNewRequest({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      status: 'pending',
      accountId: currentUser.uid,
      createdDate: moment().toDate().getTime(),
      updatedDate: moment().toDate().getTime(),
    })).then((id) => {
      if(isEmailUser)
        dispatch(appActions.sendEmail(values.email,
          `https://verify.idenfication.com/verify?requestid=${id}`,
          `${values.firstName} ${values.lastName}`,
          account.companyName || '',
          account.website || '',
          account.signature || ''
        ))
      message.success(`Request created successfully`);
      props.onFinish()
    })
      .catch((error) => {
        console.log(error)
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div style={{width: 600}}>
      <Form
        name="basic"
        labelCol={{span: 5}}
        wrapperCol={{span: 18}}
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="modal-content">
          <a data-tw-dismiss="modal" href="javascript:;">
            <X
              onClick={()=>setShowModal(false)}
              className="w-8 h-8 text-slate-400"/>
          </a>
          <div className="modal-header p-5">
            <h2 className="font-medium text-base mr-auto">Send ID verification request</h2>
          </div>
          <div className="modal-body">
      
            <div className="">
              <div className="mt-3">
                <label htmlFor="regular-form-3" className="form-label">First Name</label>
                <Form.Item
                  name="firstName"
                  rules={[{required: true, message: 'Please input your first name!'}]}
                >
                <input id="regular-form-3" type="text" className="form-control" placeholder=""/>
                </Form.Item>
              </div>
              <div className="mt-3">
                <label htmlFor="regular-form-3" className="form-label">Last Name</label>
                <Form.Item
                  name="lastName"
                  rules={[{required: true, message: 'Please input your last name!'}]}
                >
                <input id="regular-form-3" type="text" className="form-control" placeholder=""/>
                </Form.Item>
                </div>
              <div className="mt-3">
                <label htmlFor="regular-form-3" className="form-label">Email</label>
                <Form.Item
                  name="email"
                  rules={[{required: true, message: 'Please input your email!'}]}
                >
                <input id="regular-form-3" type="text" className="form-control" placeholder=""/>
                </Form.Item>
              </div>
              <div className="mt-3">
                <label htmlFor="regular-form-3" className="form-label">Contact #</label>
                <Form.Item
                  name="mobile"
                  rules={[{required: true, message: 'Please input your Phone!'}]}
                >
                <input id="regular-form-3" type="number" className="form-control" placeholder=""/>
                </Form.Item>
                </div>
              <Checkbox style={{marginTop: 7, marginBottom: 20}} checked={isEmailUser} onChange={(e)=>{setEmailUser(e.target.checked)}}>
                Notify user by Email
              </Checkbox>
              <div className="w-full sm:w-auto flex mt-4 sm:mt-5 ">
                <button className="btn btn-primary shadow-md mr-2 w-26">Send</button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default NewRequest
