import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #2c304b;
  }
  @media (max-width: 768px) {
    button {
      width: 100%;
    }
    h3 {
      font-size: 25px;
    }
  }
`;
