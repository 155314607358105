import React from "react";
import { Button } from "./styles";

const index = (props) => {
  const { handleClick, className, veriant, size, block, type, loading, buttonType, disabled  } = props;
  return (
    <Button
      onClick={handleClick}
      className={`${className}`}
      veriant={veriant}
      size={size}
      block={block}
      htmlType={type}
      loading={loading}
      type={buttonType}
      disabled={disabled}
    >
      {props.children}
    </Button>
  );
};

export default index;
