import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import authSelectors from "../../modules/auth/authSelectors";
import { Form, message } from "antd";

function NotificationSetting(props) {
  const dispatch = useDispatch();
  const account = useSelector(appSelectors.getAccount);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const [form] = Form.useForm()
  console.log('account', account)
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCompanyData(currentUser.uid))
    }
  }, [currentUser])
  useEffect(() => {
    if (account.preferences) {
      form.setFieldsValue({
        emailLinkOpen: account.preferences.emailLinkOpen ? account.preferences.emailLinkOpen : false,
        emailRequestDecline: account.preferences.emailRequestDecline ? account.preferences.emailRequestDecline: false,
        expiryDays: account.preferences.expiryDays ? account.preferences.expiryDays : '',
        emailRequestCompleted: account.preferences.emailRequestCompleted ? account.preferences.emailRequestCompleted : false,
        emailRequestExpire: account.preferences.emailRequestExpire ? account.preferences.emailRequestExpire : false,
        emailPdfMe: account.preferences.emailPdfMe ? account.preferences.emailPdfMe : false,
        emailPdfClient: account.preferences.emailPdfClient ? account.preferences.emailPdfClient : false,
        expireRequests: account.preferences.expireRequests ? account.preferences.expireRequests : false,
      })
    }
  }, [account])
  const onFinish = (values) => {
    const preferences = account.preferences ? account.preferences : {}
    dispatch(appActions.updateCompanyData(currentUser.uid, {
      uid: currentUser.uid,
      preferences: {
        ...preferences,
        ...values
      }
    })).then(
      (msg) => {
        message.success(msg)
      },
      (err) => {
        message.error(err)
      }
    )
  }
  return (
    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
      <Form
        form={form}
        name="basic"
        initialValues={{ emailPdfMe: false,emailLinkOpen: false, emailRequestDecline: false, emailRequestCompleted: false,
          emailPdfClient: false,emailRequestExpire: false, expireRequests: false, expiryDays:'' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="intro-y box lg:mt-5">
          <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">
              Notifications
            </h2>
          </div>
          <div className="p-5">
            <div className="flex flex-col-reverse xl:flex-row flex-col">
              <div className="flex-1 mt-6 xl:mt-0">
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-12 2xl:col-span-12">
                    <div>
                      <div className="form-check ">
                        <h4 style={{ fontWeight: 700 }}>Send me an email notification.</h4>
                      </div>
                      
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'emailLinkOpen'}>
                          <input id="checkbox-switch-1" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-1">When someone opens the id
                          verification link</label>
                      </div>
                      
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'emailRequestDecline'}>
                          <input id="checkbox-switch-2" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-2">When someone decline the
                          request</label>
                      </div>
                      
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'emailRequestCompleted'}>
                          <input id="checkbox-switch-3" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-3">When id verification is
                          completed</label>
                      </div>
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'emailRequestExpire'}>
                          <input id="checkbox-switch-4" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-4">Send me an email before
                          verification request expires.</label>
                      </div>
                      <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400 flex"
                           style={{ marginTop: 20, paddingBottom: 0 }}/>
                      <div className="form-check">
                        <h4 style={{ fontWeight: 700 }}>Email PDF copies ?</h4>
                      </div>
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'emailPdfMe'}>
                          <input id="checkbox-switch-5" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-5">When a id verification is
                          completed – email me a copy</label>
                      </div>
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'emailPdfClient'}>
                          <input id="checkbox-switch-6" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-6">When a id verification is
                          completed – email a copy to the client</label>
                      </div>
                      
                      <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400 flex"
                           style={{ marginTop: 20, paddingBottom: 0 }}/>
                      <div className="form-check">
                        <h4 style={{ fontWeight: 700 }}>Expiration</h4>
                      </div>
                      <div className="form-check ">
                        <Form.Item valuePropName="checked" name={'expireRequests'}>
                          <input id="checkbox-switch-7" className="form-check-input" type="checkbox"/>
                        </Form.Item>
                        <label className="form-check-label" htmlFor="checkbox-switch-7">Enable expiry</label>
                      </div>
                      <div className="mt-3">
                        <label htmlFor="update-profile-form-1" className="form-label">Expires after days ?</label><br/>
                        <Form.Item  name={'expiryDays'}>
                          <input
                            id="update-profile-form-1"
                            type="number"
                            className="form-control"
                            placeholder="Input text"
                            style={{ maxWidth: 250 }}
                          />
                        </Form.Item>
                      </div>
                    
                    
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-4">Save</button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  
  )
}

export default NotificationSetting
