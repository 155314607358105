import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../../modules/auth/authSelectors";
import { loadStripe } from "@stripe/stripe-js";
import { PRICES, STRIPE_API_KEY } from "../../Constants";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";

function Packages() {
  const dispatch = useDispatch()
  const [paymentType, setPaymentType] = useState('monthly')
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const stripePromise = loadStripe(STRIPE_API_KEY);
  const account = useSelector(appSelectors.getAccount);
  const requests = useSelector(appSelectors.selectUserRequests);
  const settings = useSelector(appSelectors.getSettings);
  
  const [loading, setLoading] = useState(false);
  
  console.log('account',account)
  const currentPlan = account ? account.currentPlan : ''
  useEffect(()=>{
    if(account){
      if(account.currentPlan && account.currentPlan.startsWith('monthly'))
        setPaymentType('monthly')
      else
        setPaymentType('annual')
    }
  },[account])
  const handlePayment = async (price) => {
    setLoading(true);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      clientReferenceId: currentUser.uid,
      customerEmail: currentUser.email,
      lineItems: [
        {
          price: price.link,
          quantity: 1,
        },
      ],
      mode: "subscription",
      cancelUrl: `${window.location.origin}/failed`,
      successUrl: `${window.location.origin}/success/${price.type}`,
    });
    
    if (error) {
      setLoading(false);
      console.log(error);
    }
  };
  
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCompanyData(currentUser.uid))
    }
  }, [currentUser])
  const renderPlan = (price)=>{
    return (
      <div className="intro-y flex-1 box py-16 lg:ml-5 mb-5 lg:mb-0"
           style={currentPlan === price.type ? {backgroundColor:'#E1CEB5'} : {}}>
        <i data-lucide="target" className="block w-12 h-12 text-primary mx-auto"></i>
        <div className="text-xl font-medium text-center mt-10">
          {price.title}<span style={{marginLeft: 20, color:'#50577A'}}>{currentPlan === price.type ? '(Current Plan)':''}</span>
        </div>
        <div className="text-slate-600 dark:text-slate-500 text-center mt-5">
        </div>
        <div className="text-slate-500 px-10 text-center mx-auto mt-2 h-10">
          {price.description}
        </div>
        <div className="flex justify-center">
          <div className="relative text-5xl font-semibold mt-8 mx-auto"><span
            className="absolute text-2xl top-0 left-0 -ml-4">$</span> {price.amount}
          </div>
        </div>
        {currentPlan !== price.type && <button
          onClick={()=>handlePayment(price)}
          type="button"
          className="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8">
          PURCHASE NOW
        </button>}
      </div>
    )
  }
  return (
    <>
      <div>
        <h2 className="intro-y text-2xl font-medium mt-10 text-center mr-auto">
          Best Price & Services
        </h2>
        <div className="intro-y flex justify-center mt-6">
          <ul className=" pricing-tabs nav nav-pills w-auto box rounded-full mx-auto overflow-hidden " role="tablist">
            <li id="layout-1-monthly-fees-tab" className="nav-item flex-1" role="presentation">
              <button
                onClick={() => setPaymentType('monthly')}
                className={`nav-link w-32 lg:w-40 py-2 lg:py-3 ${paymentType === 'monthly' ? 'active' : ''}`}
                data-tw-toggle="pill"
                data-tw-target="#layout-1-monthly-fees" type="button" role="tab"
                aria-controls="layout-1-monthly-fees" aria-selected="true"> Monthly Fees
              </button>
            </li>
            <li id="layout-1-annual-fees-tab" className="nav-item flex-1" role="presentation">
              <button
                onClick={() => setPaymentType('annual')}
                className={`nav-link w-32 lg:w-40 py-2 lg:py-3 ${paymentType === 'annual' ? 'active' : ''}`}
                data-tw-toggle="pill"
                data-tw-target="#layout-1-annual-fees" type="button" role="tab" aria-controls="layout-1-annual-fees"
                aria-selected="false"> Annual Fees
              </button>
            </li>
          </ul>
        </div>
        <div className="flex mt-10">
          <div className="tab-content" style={{width:'100%'}}>
            <div id="layout-1-monthly-fees" className="tab-pane flex flex-col lg:flex-row active" role="tabpanel"
                 aria-labelledby="layout-1-monthly-fees-tab">
              {renderPlan(PRICES[paymentType][0])}
              {renderPlan(PRICES[paymentType][1])}
              {renderPlan(PRICES[paymentType][2])}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Packages
