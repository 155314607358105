import styled from "styled-components";

export const Text = styled("p")`
  margin-top: 0px;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 50px;
  line-height: 24px;
  text-align: left;
  a {
    color: #4a60f4;
  }
`;

export const FormWrapper = styled("div")`
  .underline {
    text-decoration: underline;
    a {
      color: #707070;
    }
  }
`;
