import "antd/dist/antd.css";
import Cohere from "cohere-js";
import { AppWrapper } from "./styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./assets/css/app.css"
import 'typeface-roboto';
import { store } from "./modules/store";
import { Provider } from "react-redux";
import { CookiesProvider } from 'react-cookie';

import RoutesComponent from "./components/Route/RoutesComponent";
/*import {IntercomProvider} from "react-use-intercom";*/

// Assuming you have two redirect URIs, and the first is fo`r localhost and second is for production

Cohere.init("HQf0t6g6lLJn7jIIxqDeuL7q");
/*const salt = bcrypt.genSaltSync(10)
console.log('salt',salt)*/
//const INTERCOM_APP_ID = 'at0ppvof';
const App = () => {
  return (
    <Provider store={store}>
      <CookiesProvider>
        {/*<IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
        >*/}
        <AppWrapper>
          <Router>
            <RoutesComponent/>
          </Router>
        </AppWrapper>
        {/*</IntercomProvider>*/}
      </CookiesProvider>
    </Provider>
  );
};

export default App;
