import appActions from "./appActions";

const initialData = {
  loading: false,
  errorMessage: null,
  searchText: '',
  transactions: [],
  requests: [],
  billings: [],
  coupons:[],
  account: {},
  settings: {},
  usedRequests: 0,
};

export default (state = initialData, {type, payload}) => {

  if (type === appActions.LOADING) {
    return {
      ...state,
      loading: payload.loading
    }
  }
  else if (type === appActions.GET_REQUESTS) {
    return {
      ...state,
      requests: payload.requests
    }
  }
  else if (type === appActions.GET_COUPONS) {
    return {
      ...state,
      coupons: payload.coupons,
      usedRequests: payload.usedRequests
    }
  }
  else if (type === appActions.GET_BILLINGS) {
    return {
      ...state,
      billings: payload.billings
    }
  }
  else if (type === appActions.GET_ACCOUNT) {
    return {
      ...state,
      account: payload.account
    }
  }
  else if (type === appActions.GET_APP_SETTINGS) {
    return {
      ...state,
      settings: payload.settings
    }
  }
  return state;
}
