import React, { useEffect } from "react";
import { Activity, Box, Lock } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import authSelectors from "../../modules/auth/authSelectors";

function UserSetting(props) {
  const account = useSelector(appSelectors.getAccount);
  const coupons = useSelector(appSelectors.selectUsedCoupons);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const dispatch = useDispatch();
  const { selected, onChange } = props
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCoupons(currentUser.uid))
    }
  }, [currentUser])
  return (
    <div className="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
      <div className="intro-y box mt-5">
        <div className="relative flex items-center p-5">
          <div className="w-12 h-12 image-fit">
            <img
              alt=""
              className="rounded-full"
              src={account && account.profilePicture ? account.profilePicture : require("./../../assets/images/profile-6.jpg") }
            />
          </div>
          <div className="ml-4 mr-auto">
            <div className="font-medium text-base">{account ? account.displayName : ''}</div>
            <div className="text-slate-500">{account ? account.email : ''}</div>
          </div>
        </div>
        <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <a
            onClick={() => onChange('profile')}
            className={`flex items-center ${selected === 'profile' ? 'font-medium text-primary' : ''}`}>
            <Activity className="w-4 h-4 mr-2"/> Personal Information
          </a>
          <a
            onClick={() => onChange('password')}
            className={`flex items-center mt-5 ${selected === 'password' ? 'font-medium text-primary' : ''}`}>
            <Lock className="w-4 h-4 mr-2"/>
            Change Password
          </a>
         {/* <a
            onClick={() => onChange('2fa')}
            className={`flex items-center mt-5 ${selected === '2fa' ? 'font-medium text-primary' : ''}`}>
            <Lock className="w-4 h-4 mr-2"/>
            Setup 2FA
          </a>*/}
        </div>
        <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <a
            onClick={() => onChange('notifications')}
            className={`flex items-center ${selected === 'notifications' ? 'font-medium text-primary' : ''}`}>
            <Box className="w-4 h-4 mr-2"/>
            Notification Settings
          </a>
          <a
            onClick={() => onChange('branding')}
            className={`flex items-center mt-5 ${selected === 'branding' ? 'font-medium text-primary' : ''}`}>
            <Activity className="w-4 h-4 mr-2"/>
            Branding
          </a>
          <a
            onClick={() => onChange('billing')}
            className={`flex items-center mt-5 ${selected === 'billing' ? 'font-medium text-primary' : ''}`}>
            <Box className="w-4 h-4 mr-2"/>
            Billing
          </a>
          {/*<a href={'/settings/packages'}
            className={`flex items-center mt-5 ${selected === 'packages' ? 'font-medium text-primary' : ''}`}>
            <Lock className="w-4 h-4 mr-2"/>
            Packages
          </a>*/}
          {coupons && coupons.length > 0 && <a onClick={() => onChange('apikey')}
             className={`flex items-center mt-5 ${selected === 'packages' ? 'font-medium text-primary' : ''}`}>
            <Lock className="w-4 h-4 mr-2"/>
            Api Key
          </a>}
        </div>
      </div>
    </div>
  )
}

export default UserSetting
