import styled from "styled-components";

export const SignupWrapper = styled("div")`
  //   background: red;
  .google__btn {
    margin-top: -10px;
  }
  .signup__form--label {
    margin-top: 15px;
  }
  .signup__submit--btn {
    margin-top: 0px;
  }
  .get__started__btn--span {
    color: #c5c5c5;
    margin-left: 5px;
  }
`;
export const FormWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 60vh;
  form {
    width: 100%;
  }
`;
export const Spacer = styled("div")`
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 1px solid #d3d6eb;
  position: relative;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    margin: 0 auto;
    background: white;
    padding: 0 36px;
    top: 9px;
    justify-content: center;
    display: inline;
  }
`;
export const Navigator = styled("p")`
  margin-top: 30px;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  a {
    color: #4a60f4;
  }
`;
export const PopupWrapper = styled.div`
  background: #3d54ec;
  position: absolute;
  width: 494px;
  right: 7px;
  border-radius: 10px;
  padding: 20px;
  top: 4px;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    position: absolute;
    border-bottom: 30px solid #466bec;
    transform: rotate(-43deg);
    bottom: -10px;
    left: 40px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
export const PopupMsg = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
`;
export const PopupDetails = styled.div`
  h3 {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    text-align: center;
    color: #ffffff;
  }
`;
