export default class PermissionChecker {
  currentUser;

  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  get isAuthenticated() {
    return (Boolean(this.currentUser));
  }

  get isEmailVerified() {
    if (!this.isAuthenticated) {
      return false;
    }

    return this.currentUser.userConfirmed;
  }

  get isExtraInfoPending() {
    if (!this.isAuthenticated) {
      return false;
    }
    return !this.currentUser.attributes['custom:profession'];
  }
}
