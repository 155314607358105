import React, { useEffect } from "react";
import "./FailurePage.css";
import FailureImage from "./FailureImage.png";

function FailurePage() {

  useEffect(() => {
  
  },[])

  return (
    <div className="success-page-container">
      <img src={FailureImage} alt="success" />
      <h1 className="payment-failure">Payment Failed !</h1>
      <a href={`/requests`}>Go to home</a>
    </div>
  );
}

export default FailurePage;
