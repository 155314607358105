import { getHistory } from '../store';
import { onAuthStateChanged, updatePassword } from "firebase/auth";
import { auth } from "../../firebase";
import moment from "moment";

const prefix = 'AUTH';

const authActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,
  
  AUTH_INIT_START: `${prefix}_INIT_START`,
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,
  
  AUTH_CONFIRM_SENT: `${prefix}_CONFIRM_SENT`,
  
  AUTH_LOGOUT_SUCCESS: `${prefix}_LOGOUT_SUCCESS`,
  
  AUTH_LOADING: `${prefix}_LOADING`,
  AUTH_LOADING_STOP: `${prefix}_LOADING_STOP`,
  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,
  
  doInit: () => async (dispatch) => {
    console.log('doint')
    try {
      dispatch({
        type: authActions.AUTH_INIT_START,
        payload: {},
      });
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          var a = moment();
          var b = moment(user.metadata.lastLoginAt, 'x');
          const days = a.diff(b, 'days')
          if (days < 7) {
            dispatch({
              type: authActions.AUTH_INIT_SUCCESS,
              payload: {
                currentUser: user,
                billing: {}
              },
            });
          } else {
            dispatch({
              type: authActions.AUTH_INIT_ERROR,
              payload: {
                currentUser: null,
                billing: {}
              },
            });
          }
        } else {
          /*const cookies = new Cookies();
          const isCodeSent = cookies.get('codeSent');
          if (isCodeSent) {
            dispatch({
              type: authActions.AUTH_CONFIRM_SENT,
              payload: {confirmSent: true}
            });
          }*/
          dispatch({
            type: authActions.AUTH_INIT_ERROR,
            payload: {
              currentUser: null,
              billing: {}
            },
          });
        }
      });
    } catch (e) {
      dispatch({
        type: authActions.AUTH_INIT_ERROR,
      });
    }
  },
  loading: () => async (dispatch) => {
    dispatch({
      type: authActions.AUTH_LOADING,
      payload: {},
    });
  },
  stopLoading: () => async (dispatch) => {
    dispatch({
      type: authActions.AUTH_LOADING_STOP,
      payload: {},
    });
  },
  updateConfirmSent: (sent) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.AUTH_CONFIRM_SENT,
        payload: { confirmSent: sent },
      });
    } catch (e) {
      console.log(e)
    }
  },
  updateAuth: (route) => async (dispatch) => {
    /*try {
      dispatch({
        type: authActions.AUTH_INIT_START,
        payload: {},
      });
      const currentUser = await Auth.currentAuthenticatedUser();

      if (currentUser) {
        Cohere.identify(
          currentUser.username, // Required: can be any unique ID
          {
            displayName: currentUser.attributes.name, // Optional
            email: currentUser.attributes.email, // Optional
          }
        );
        await dispatch({
          type: authActions.AUTH_INIT_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } else {
        dispatch({
          type: authActions.AUTH_INIT_ERROR,
        });
      }
      getHistory().replace(route ? route : "/blog-upload");

    } catch (error) {
      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }*/
  },
  doLogout: () => async (dispatch) => {
    try {
      await auth.signOut()
      /* await Auth.signOut()
       await dispatch({
         type: authActions.AUTH_LOGOUT_SUCCESS,
         payload: {},
       });*/
      getHistory().push("/login");
    } catch (error) {
    }
  }
  
};


export default authActions;
