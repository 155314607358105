import styled from "styled-components";
import { Checkbox as Chk } from "antd";

export const Checkbox = styled(Chk)`
  margin-top: 10px;
  margin-bottom: 0px;
  span:nth-child(2) {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: #707070;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4f6ff4;
    border-color: #4f6ff4;
  }
`;
