import React, { useState } from "react";
import { Dropdown, Menu, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { BarChart2, Box, Edit, HardDrive, HelpCircle, Lock, Settings, ToggleRight, User, XCircle } from 'lucide-react';
import authSelectors from "../modules/auth/authSelectors";
import authActions from "../modules/auth/authActions";
import appSelectors from "../modules/app/appSelectors";


const Index = (props) => {
  console.log('props', props)
  const { slot } = props
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false);
  const account = useSelector(appSelectors.getAccount);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  
  const handleLogout = async (event) => {
    try {
      dispatch(authActions.doLogout())
      message.success("You have Successfully logged out!");
    } catch (error) {
      message.success(error.message);
    }
  };
  
  
  const MenuOptions = () => {
    return (
      <>
        {/*<li>
          <a href="#" className="side-menu menu">
            <div className="side-menu__icon"><Inbox/></div>
            <div className="side-menu__title menu__title"> Dashboard</div>
          </a>
        </li>*/}
        <li>
          <a href="/requests" className={`side-menu menu ${slot === 'requests' ? 'side-menu--active' : ''}`}>
            <div className="side-menu__icon"><HardDrive/></div>
            <div className="side-menu__title menu__title"> ID Listing</div>
          </a>
        </li>
        {/*<li>
          <a href="#" className="side-menu menu">
            <div className="side-menu__icon"><Users/></div>
            <div className="side-menu__title menu__title"> Team</div>
          </a>
        </li>*/}
        <li>
          <a href="/settings/packages" className={`side-menu menu ${slot === 'packages' ? 'side-menu--active' : ''}`}>
            <div className="side-menu__icon"><Box/></div>
            <div className="side-menu__title menu__title"> Packages</div>
          </a>
        </li>
        <li>
          <a href="/settings" className={`side-menu menu ${slot === 'settings' ? 'side-menu--active' : ''}`}>
            <div className="side-menu__icon"><Settings/></div>
            <div className="side-menu__title menu__title"> Settings</div>
          </a>
        </li>
      </>
    )
  }
  const menu = () => {
    return (
      <Menu>
        <Menu.Item>
          <li className="p-2 w-20">
            <div className="font-medium" style={{ color: 'white' }}>{account.displayName}</div>
            <div className="text-xs text-white/70 mt-0.5 dark:text-slate-500">{account.email}</div>
          </li>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item>
          <a href="/settings/profile" className="dropdown-item hover:bg-white/5"> <User
            className="w-4 h-4 mr-2"/> Profile </a>
        </Menu.Item>
        {/*<Menu.Item>
          <a href="" className="dropdown-item hover:bg-white/5">
            <Edit data-lucide="edit" className="w-4 h-4 mr-2"/> Add Account
          </a>
        </Menu.Item>*/}
        <Menu.Item>
          <a href="" className="dropdown-item hover:bg-white/5">
            <Lock data-lucide="lock" className="w-4 h-4 mr-2"/> Reset Password
          </a>
        </Menu.Item>
       {/* <Menu.Item>
          <a href="" className="dropdown-item hover:bg-white/5"> <HelpCircle
            className="w-4 h-4 mr-2"/> Help </a>
        </Menu.Item>*/}
        <Menu.Divider/>
        <Menu.Item>
          <a
            onClick={handleLogout}
            href="" className="dropdown-item hover:bg-white/5"> <ToggleRight
            className="w-4 h-4 mr-2"/> Logout </a>
        </Menu.Item>
      </Menu>
    )
    /*return(
      <div
        className="dropdown-menu w-56"
        style={{
          width: 224,
          position: 'absolute',
          top:0,
          right:0,
          left:'auto',
          bottom:'auto',
          margin: 0,
          backgroundColor:'red',
          display: 'contents'
        }}
      >
        <ul className="dropdown-content bg-primary text-white" style={{visibility:'visible',  width: 224,}}>
          <li className="p-2">
            <div className="font-medium">Johnny Depp</div>
            <div className="text-xs text-white/70 mt-0.5 dark:text-slate-500">Backend Engineer</div>
          </li>
          <li>
            <hr className="dropdown-divider border-white/[0.08]"/>
          </li>
          <li>
            <a href="" className="dropdown-item hover:bg-white/5"> <User
              className="w-4 h-4 mr-2"/> Profile </a>
          </li>
          <li>
            <a href="" className="dropdown-item hover:bg-white/5"> <Edit data-lucide="edit"
                                                                         className="w-4 h-4 mr-2"/> Add Account
            </a>
          </li>
          <li>
            <a href="" className="dropdown-item hover:bg-white/5"> <Lock data-lucide="lock"
                                                                         className="w-4 h-4 mr-2"/> Reset
              Password
            </a>
          </li>
          <li>
            <a href="" className="dropdown-item hover:bg-white/5"> <HelpCircle
              className="w-4 h-4 mr-2"/> Help </a>
          </li>
          <li>
            <hr className="dropdown-divider border-white/[0.08]"/>
          </li>
          <li>
            <a href="" className="dropdown-item hover:bg-white/5"> <ToggleRight
              className="w-4 h-4 mr-2"/> Logout </a>
          </li>
        </ul>
      </div>
    )*/
  };
  return (
    <div
      className="py-5">
      <div id={'mobile-menu'} className="mobile-menu md:hidden">
        <div className="mobile-menu-bar">
          <a href="" className="flex mr-auto">
            <img alt="#" className="w-6" src={require("./../assets/images/logo.svg").default}/>
          </a>
          <a
            onClick={() => {
              const el = document.getElementById('mobile-menu')
              el.classList.toggle('mobile-menu--active')
            }}
            className="mobile-menu-toggler">
            <BarChart2 className="w-8 h-8 text-white transform -rotate-90"/>
          </a>
        </div>
        <div className="scrollable">
          <a
            onClick={() => {
              const el = document.getElementById('mobile-menu')
              el.classList.toggle('mobile-menu--active')
            }}
            className="mobile-menu-toggler">
            <XCircle className="w-8 h-8 text-white transform -rotate-90"/>
          </a>
          <ul className="scrollable__content py-2">
            <MenuOptions/>
          </ul>
        </div>
      </div>
      <div className="flex mt-[4.7rem] md:mt-0">
        <nav className="side-nav">
          <a href="" className="intro-x flex items-center pl-5 pt-4">
            <img alt="Midone - HTML Admin Template" className="w-6"
                 src={require("./../assets/images/logo.svg").default}/>
            <span className="hidden xl:block text-white text-lg ml-3"> Idenfication </span>
          </a>
          <div className="side-nav__devider my-6"></div>
          <ul>
            <MenuOptions/>
          </ul>
        </nav>
        <div className="content">
          <div className="top-bar">
            <nav aria-label="breadcrumb" className="-intro-x mr-auto hidden sm:flex">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Application</a></li>
                <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
              </ol>
            </nav>
            
            <div className="intro-x dropdown">
              
              <Dropdown
                trigger={'click'}
                overlay={menu}
                className="intro-x dropdown show"
                placement="bottomLeft" arrow>
                <div
                  style={{ float: 'right' }}
                  onClick={() => setCollapsed(true)}
                  className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
                  role="button" aria-expanded="false" data-tw-toggle="dropdown">
                  <img
                    alt=""
                    src={account && account.profilePicture ? account.profilePicture : require("./../assets/images/profile-14.jpg")}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  )
};

export default Index;
