import React from "react";
import { HeaderWrapper } from "./styles";

const Index = (props) => {
  const { title } = props;
  return (
    <HeaderWrapper>
      <h3>{title}</h3>
      {props.children}
    </HeaderWrapper>
  );
};

export default Index;
