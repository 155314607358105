import { Copy, FileDown, Mail, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../../../modules/auth/authSelectors";
import appSelectors from "../../../modules/app/appSelectors";
import appActions from "../../../modules/app/appActions";
import NewRequest from "../../../components/NewRequest";
import { CheckOutlined } from '@ant-design/icons';
import { Tooltip, message, Modal as AntModal } from "antd";
import moment from "moment";

const { confirm } = AntModal;

function Requests(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [userRequests, setUserRequests] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('');
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const requests = useSelector(appSelectors.selectUserRequests);
  const settings = useSelector(appSelectors.getSettings);
  const account = useSelector(appSelectors.getAccount);
  const usedRequests = useSelector(appSelectors.selectUsedRequests);
  
  useEffect(() => {
    dispatch(appActions.getAppSettings())
  }, [])
  
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getRequests(currentUser.uid))
      dispatch(appActions.getCompanyData(currentUser.uid))
      dispatch(appActions.getCoupons(currentUser.uid))
    }
  }, [currentUser])
  
  useEffect(() => {
    setUserRequests(requests)
  }, [requests])
  
  useEffect(() => {
    if (searchText) {
      const _jobs = requests.filter((request) => (`${request.firstName} ${request.lastName}`.toLowerCase().includes(searchText.toLowerCase())))
      setUserRequests(_jobs)
    } else {
      setUserRequests(requests)
    }
  }, [searchText])
  
  useEffect(() => {
    if (status && status !== 'all') {
      const _jobs = requests.filter((job) => (job.status && job.status.toLowerCase() === status.toLowerCase()))
      setUserRequests(_jobs)
    } else {
      setUserRequests(requests)
    }
  }, [status])
  
  return (
    <div>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">
          ID Requests
        </h2>
        <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            onClick={() => {
              if (usedRequests >= account.reqAllowed) {
                history.push('/app-sumo')
              } else
                document.getElementById('button-slide-over-preview').classList.toggle('show')
            }}
            className="btn btn-primary shadow-md mr-2" data-tw-toggle="modal"
            data-tw-target="#button-slide-over-preview">Add New Request
          </button>
        </div>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form id="tabulator-html-filter-form" className="xl:flex sm:mr-auto">
            <div className="sm:flex items-center sm:mr-4">
              <label className="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Status</label>
              <select
                onChange={(e) => setStatus(e.target.value)}
                id="tabulator-html-filter-field"
                className="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="in progress">In Progress</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <label className="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Name</label>
              <input
                onChange={(e) => setSearchText(e.target.value)}
                id="tabulator-html-filter-value"
                type="text"
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Name"
              />
            </div>
            {/*<div className="mt-2 xl:mt-0">
              <button id="tabulator-html-filter-go" type="button" className="btn btn-primary w-full sm:w-16">Search
              </button>
            </div>*/}
          </form>
        </div>
        <div className="overflow-x-auto scrollbar-hidden" style={{ width: '100%' }}>
          <div id="tabulatorTMP" className="mt-5 table-report table-report--tabulator"></div>
          
          <div id="tabulator234" className="mt-5 table-report table-report--tabulator tabulator" role="grid"
               style={{ minWidth: 800 }}>
            <div className="tabulator-header" style={{ paddingRight: 0, marginLeft: 0 }}>
              <div className="tabulator-headers" style={{ marginLeft: 0 }}>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 200, height: 44, width: '25%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">NAME</div>
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '15%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Contact #</div>
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '15%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Last Modified Date</div>
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '15%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Captured</div>
                    
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '15%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Status</div>
                    
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '15%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Actions</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabulator-frozen-rows-holder"></div>
            </div>
            
            
            <div className="tabulator-tableHolder" tabIndex="0" style={{ height: '60vh' }}>
              <div className="tabulator-table" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                {userRequests && userRequests.map((request) =>
                  <div className="tabulator-row tabulator-selectable tabulator-row-odd" role="row"
                       style={{ paddingLeft: 0 }}>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{ width: '25%', display: 'inline-flex', alignItems: 'center', height: 64 }}>
                      <div>
                        <div
                          className="font-medium whitespace-nowrap">{`${request.firstName} ${request.lastName}`}</div>
                        <div className="text-slate-500 text-xs whitespace-nowrap">{`${request.email}`}</div>
                      </div>
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "15%",
                           textAlign: 'left',
                           display: 'inline-flex',
                           alignItems: 'center',
                           justifyContent: 'left',
                           height: 64
                         }}>
                      {request.mobile}
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "15%",
                           textAlign: "center",
                           display: 'inline-flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           height: 64
                         }}>
                      {request.updatedDate ? moment(request.updatedDate).format('DD/MM/YYYY hh:mm A') : ''}
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "15%",
                           textAlign: 'center',
                           display: 'inline-flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           height: 64
                         }}>
                      <div className="flex lg:justify-center">
                        <div className="intro-x w-10 h-10 image-fit">
                          {request.recorded ? <CheckOutlined style={{ color: 'green' }}/> : null}
                        </div>
                      </div>
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "15%",
                           textAlign: "center",
                           display: "inline-flex",
                           alignItems: "center",
                           justifyContent: "center",
                           height: 64
                         }}>
                      <span
                        style={{
                          color: "#fff",
                          textDecoration: "none",
                          padding: "3px 8px 3px 8px",
                          borderRadius: 15,
                          width: 105
                        }}
                        className={`${request.status === 'completed' ? 'bg-success' :
                          request.status === 'error' ? 'bg-danger' :
                            request.status === 'pending' ? 'bg-pending' : 'bg-warning'} tooltip`}
                      >
                        {request.status ? request.status.toUpperCase() : ''}</span>
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "15%",
                           textAlign: "center",
                           display: "inline-flex",
                           alignItems: "center",
                           justifyContent: "center",
                           height: 64
                         }}>
                      <div className="flex lg:justify-center items-center">
                        <Tooltip
                          color={"black"}
                          placement="top" title={"Download Report"} key={'copy'+request.id} >
                          {request.reportUrl &&
                          <a href={request.reportUrl} target="_blank" download className="edit flex items-center mr-3">
                            <FileDown color={'#1363DF'} className="w-4 h-4"/>
                          </a>
                          }
                          {!request.reportUrl &&
                          <div className="edit flex items-center mr-3">
                            <FileDown color={'#CFD2CF'} className="w-4 h-4"/>
                          </div>
                          }
                        </Tooltip>
                        <a
                          onClick={() => {
                            if (!request.requestClosed) {
                              const input = document.createElement('input');
                              input.setAttribute('value', `https://verify.idenfication.com/verify?requestid=${request.id}`);
                              document.body.appendChild(input);
                              input.select();
                              document.execCommand('copy');
                              document.body.removeChild(input);
                              message.success("Copied to clipboard");
                            }
                          }}
                          className="edit flex items-center mr-3">
                          <Tooltip
                            color={"black"}
                            placement="top" title={"Copy to clipboard"} key={'copy' + request.id}>
                            <Copy
                              color={request.requestClosed ? '#CFD2CF' : '#1363DF'}
                              className="w-4 h-4"/>
                          </Tooltip>
                        </a>
                        <a
                          onClick={() => {
                            if (!request.requestClosed) {
                              dispatch(appActions.sendEmail(request.email,
                                `https://verify.idenfication.com/verify?requestid=${request.id}`,
                                `${request.firstName} ${request.lastName}`
                              ))
                                .then(function (response) {
                                  message.success(`Email sent to ${request.email}`);
                                }, function (err) {
                                  console.log('FAILED...', err);
                                });
                            }
                          }}
                          className="edit flex items-center mr-3">
                          <Tooltip
                            color={"black"}
                            placement="top" title={"Email link to user"} key={'copy' + request.id}>
                            <Mail
                              color={request.requestClosed ? '#CFD2CF' : '#1363DF'}
                              className="w-4 h-4"/>
                          </Tooltip>
                        </a>
                        {request.status === 'pending' &&
                        <a
                          onClick={() => {
                            confirm({
                              title: 'Are you sure',
                              content: 'Do you Want to delete these request? This action cannot be undone.',
                              onOk() {
                                console.log('OK');
                                dispatch(appActions.deleteRequests(request.id))
                                dispatch(appActions.getRequests(currentUser.uid))
                              },
                              onCancel() {
                                console.log('Cancel');
                              },
                            });
                          }}
                          className="delete flex items-center text-danger">
                          <Tooltip
                            color={"black"}
                            placement="top" title={"Delete Request"} key={'copy' + request.id}>
                            <Trash2 className="w-4 h-4 mr-1"/>
                          </Tooltip>
                        </a>
                        }
                        {request.status !== "pending" && <Trash2 color={'#CFD2CF'} className="w-4 h-4 mr-1"/>}
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
            
            </div>
            
            
            <div
              className="tabulator-footer">
                <span className="tabulator-paginator"
                      style={{ justifyContent: "flex-end" }}>
                    <button className="tabulator-page" type="button" role="button"
                            aria-label="First Page" title="First Page" data-page="first"
                            disabled="">First</button><button className="tabulator-page" type="button"
                                                              role="button" aria-label="Prev Page"
                                                              title="Prev Page" data-page="prev"
                                                              disabled="">Prev</button><span
                  className="tabulator-pages"><button className="tabulator-page active" type="button"
                                                      role="button" aria-label="Show Page 1"
                                                      title="Show Page 1"
                                                      data-page="1">1</button></span>
                    <button className="tabulator-page" type="button" role="button"
                            aria-label="Next Page" title="Next Page" data-page="next"
                            disabled="">Next</button>
                    <button className="tabulator-page" type="button" role="button"
                            aria-label="Last Page" title="Last Page" data-page="last"
                            disabled="">Last</button>
                </span>
            </div>
          </div>
        </div>
      </div>
      
      <div
        id="button-slide-over-preview"
        className="modal modal-slide-over overflow-y-auto"
        data-tw-backdrop="static" tabIndex="-1"
        aria-hidden="true"
        style={{
          marginTop: 0,
          marginLeft: 0,
          paddingLeft: 0,
          zIndex: 10000
        }}
      >
        <div className="modal-dialog">
          <NewRequest
            onFinish={() => {
              document.getElementById('button-slide-over-preview').classList.toggle('show')
              dispatch(appActions.getRequests(currentUser.uid))
            }}
            setShowModal={() => {
              document.getElementById('button-slide-over-preview').classList.toggle('show')
            }}
          />
        </div>
      </div>
      
      
      {/*{showModal &&
      <Modal
        closeModal={() => {
          setShowModal(false);
          // history.replace('', null);
        }}
      >
        <Header>
          New Request
        </Header>
        <NewRequest
          onFinish={() => {
            setShowModal(false)
            dispatch(appActions.getRequests(currentUser.uid))
          }}
        />
      </Modal>
      }*/}
    </div>
  )
}

export default Requests
