import styled from "styled-components";

export const LoginWrapper = styled("div")`
  .google__btn {
    margin-top: -20px;
  }
  .login__form--label {
    margin-top: 15px;
  }
  .login__submit--btn {
    margin-top: 10px;
  }
  .get__started__btn--span {
    color: #c5c5c5;
    margin-left: 5px;
  }
  .remember {
    text-align: center;
    margin: 10px 0;
  }
`;
export const FormWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    width: 100%;
  }
`;
export const Spacer = styled("div")`
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 1px solid #d3d6eb;
  position: relative;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    margin: 0 auto;
    background: white;
    padding: 0 36px;
    top: 9px;
    justify-content: center;
    display: inline;
  }
`;
export const Navigator = styled("p")`
  margin-top: 70px;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  @media (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  a {
    color: #4a60f4;
  }
`;
export const ForgetPassword = styled("div")`
  margin-top: -10px;
  margin-bottom: 20px;
  a {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #707070;
  }
`;
