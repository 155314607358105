import {Col, Form, Image, message, Row, Table, Upload} from "antd";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import SubHeader from "../../../components/Dashboard/SubHeader";
import Button from "./../../../components/Button";
import Plus from "./../../../assets/plus.png";
import {ContentWrapper, Label} from "./../../../styles";
import appActions from "../../../modules/app/appActions";
import {useDispatch, useSelector} from "react-redux";
import authSelectors from "../../../modules/auth/authSelectors";
import appSelectors from "../../../modules/app/appSelectors";
import authActions from "../../../modules/auth/authActions";
import moment from "moment";
import {Input} from "./styles";

const Index = () => {
  console.log('billing')
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const account = useSelector(appSelectors.getAccount);
  const [form] = Form.useForm()
  const onFinish = (values) => {
    dispatch(appActions.updateCompanyData(currentUser.uid, {
      uid: currentUser.uid,
      ...values
    })).then(
      (msg)=>{message.success(msg)},
      (err)=>{message.error(err)}
    )
  }
  useEffect(() => {
    dispatch(appActions.getCompanyData(currentUser.uid))
  }, [currentUser])

  useEffect(() => {
    if(account) {
      form.setFieldsValue({
        name: account.displayName,
        email: account.email,
        companyName: account.companyName,
        website: account.website
      })
    }
  }, [account])

  return (
    <Row justify={'center'}>
      <Col md={12}>
        <Form
          form={form}
          style={{marginTop: 40}}
          name="basic"
          labelCol={{span: 5}}
          wrapperCol={{span: 18}}
          initialValues={{remember: true}}
          onFinish={onFinish}
          autoComplete="off"
          hideRequiredMark
        >
          <Form.Item
            label="Full Name"
            name="name"
            rules={[{required: true, message: 'Please input your username!'}]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{required: true, message: 'Please input your username!'}]}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            label="Company name"
            name="companyName"
            rules={[{required: true, message: 'Please input your company name!'}]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="Website"
            name="website"
            rules={[{required: true, message: 'Please input your website!'}]}
          >
            <Input/>
          </Form.Item>
          <div style={{width: '100%', display: 'grid', padding: '30px 20%'}}>
            <Button
              veriant="primary"
              size="small"
              type={'submit'}
            >
              Update
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default Index;
