import React from "react";
import {Col, Form, Input, Row} from "antd";
import SubHeader from "./../../../components/Dashboard/SubHeader";
import {FormWrapper, Text} from "./styles";
import {Label} from "../../../styles";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import authSelectors from "../../../modules/auth/authSelectors";

const Index = () => {
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const onFinish = (values) => {
  };
  const handleChange = (e) => {
  };

  return (
    <Row>
      <Col sm={24} md={10}>
        <SubHeader title="Account info"/>
        <FormWrapper>
          <Form
            name="nest-messages" onFinish={onFinish}
            initialValues={{
              name: currentUser ? currentUser.attributes.name : '',
              email: currentUser ? currentUser.attributes.email : ''
            }}>
            <Label>Name</Label>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input className="input" onChange={handleChange} disabled/>
            </Form.Item>
            <Label>Email</Label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input className="input" onChange={handleChange} size="medium" disabled/>
            </Form.Item>
            <Text className="underline">
              <Link to="/reset-password">Change password</Link>
            </Text>

          </Form>
        </FormWrapper>
      </Col>
      <Label className="underline" style={{textDecoration: 'underline', color: '#707070'}}>
        <Link style={{color: '#707070'}} to="#" onClick={(e) => {
          window.location = 'mailto:support@spread.software';
          e.preventDefault();
        }}>
          To unsubscribe from email notifications and marketing, or to delete your account please email our support
          team.
        </Link>
      </Label>
    </Row>
  );
};

export default Index;
