import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import authSelectors from "../../modules/auth/authSelectors";
import { Form, message } from "antd";
import { X } from "lucide-react";

function ApiKey() {
  const dispatch = useDispatch();
  const account = useSelector(appSelectors.getAccount);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const [password, setPassword] = useState('');
  const [form] = Form.useForm()
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCompanyData(currentUser.uid))
    }
  }, [currentUser])
  useEffect(() => {
    console.log('account', account)
  }, [account])
  const genPassword = ()=> {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 32;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber +1);
    }
    setPassword(password);
    dispatch(appActions.updateKey(currentUser.uid, password))
    dispatch(appActions.getCompanyData(currentUser.uid))
  }
  const onFinish = async (values) => {
    console.log('Success:', account);
    
  }
  return (
    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="intro-y box lg:mt-5">
          <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">
              Api Key
            </h2>
          </div>
          <div className="p-5">
            <div className="flex flex-col-reverse xl:flex-row flex-col">
              <div className="flex-1 mt-6 xl:mt-0">
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-6">
                    {account?.key}
                  </div>
                  <div className="col-span-6">
                    <button
                      onClick={()=>{
                        genPassword()
                        document.getElementById('button-slide-over-preview').classList.toggle('show')
                      }}
                      className="btn btn-primary w-50 float-right">{account?.key ? 'Create new api key':'Create api key'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </Form>
      <div
        id="button-slide-over-preview"
        className="modal modal-slide-over overflow-y-auto"
        data-tw-backdrop="static" tabIndex="-1"
        aria-hidden="true"
        style={{
          marginTop: 0,
          marginLeft: 0,
          paddingLeft: 0,
          zIndex: 10000
        }}
      >
        <div style={{width: 400, height:'100%', backgroundColor:'white', float:'right'}}>
          <a data-tw-dismiss="modal" href="javascript:;">
            <X
              onClick={()=>{
                document.getElementById('button-slide-over-preview').classList.toggle('show')
              }}
              className="w-8 h-10 text-slate-400"/>
          </a>
          <div className="mt-14" style={{width:'100%', fontSize: 18, fontWeight:'bold', textAlign: 'center'}}>
            Your api key
          </div>
          <div className="mt-12 text-center">
            {password}
          </div>
          <div className="mt-12" style={{width:'100%', fontSize: 16, padding: 30, textAlign: 'center'}}>
            Due to security concerns your key will not displayed again. Please copy at some safe place.
          </div>
        </div>
      </div>
    </div>
  
  )
}

export default ApiKey
