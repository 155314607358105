import React, { useState } from "react";
import UserSetting from "../../components/Settings";
import ProfileSetting from "../../components/Profile";
import ChangePassword from "../../components/ChangePassword";
import NotificationSettings from "../../components/NotificationSettings";
import Branding from "../../components/Branding";
import Billing from "../../components/Billing";
import ApiKey from "../../components/ApiKey";

function Settings() {
  const [selectedItem, setSelectedItem] = useState('profile')
  return (
    <div>
      <div className="intro-y flex items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">
          Update Profile
        </h2>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <UserSetting
          selected={selectedItem}
          onChange={(item) => setSelectedItem(item)}
        />
        {selectedItem === 'profile' && <ProfileSetting/>}
        {selectedItem === 'password' &&  <ChangePassword/>}
        {selectedItem === 'notifications' &&  <NotificationSettings/>}
        {selectedItem === 'branding' &&  <Branding/>}
        {selectedItem === 'billing' &&  <Billing/>}
        {selectedItem === 'apikey' &&  <ApiKey/>}
       
      </div>
    </div>
  )
}

export default Settings
