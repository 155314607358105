import {createSelector} from 'reselect';

const selectRaw = (state) => state.app;

const selectUserRequests = createSelector(
  [selectRaw],
  (app) => app.requests,
);
const selectUsedCoupons = createSelector(
  [selectRaw],
  (app) => app.coupons,
);
const selectUsedRequests = createSelector(
  [selectRaw],
  (app) => app.usedRequests,
);
const selectUserBillings = createSelector(
  [selectRaw],
  (app) => app.billings,
);
const selectLoading = createSelector(
  [selectRaw],
  (app) => app.loading,
);
const selectSearchText = createSelector(
  [selectRaw],
  (app) => app.searchText,
);
const getAccount = createSelector(
  [selectRaw],
  (app) => app.account,
);
const getSettings = createSelector(
  [selectRaw],
  (app) => app.settings,
);
const selectors = {
  selectRaw,
  selectLoading,
  selectUserRequests,
  selectSearchText,
  getAccount,
  getSettings,
  selectUserBillings,
  selectUsedCoupons,
  selectUsedRequests
};

export default selectors;
