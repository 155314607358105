import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import authSelectors from "../../modules/auth/authSelectors";
import { Form, message } from "antd";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

function ChangePassword() {
  const dispatch = useDispatch();
  const account = useSelector(appSelectors.getAccount);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  //const [passwordStrength, setPasswordStrength] = useState(0);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [form] = Form.useForm()
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCompanyData(currentUser.uid))
    }
  }, [currentUser])
  useEffect(() => {
    console.log('account', account)
  }, [account])
  const strengthChecker = (e) => {
    /*const password = e.target.value
    setPassword(password)
    if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/gms)) {
      setPasswordStrength(4)
    } else if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/gms)) {
      setPasswordStrength(3)
    } else if (password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      setPasswordStrength(2)
    } else if (password.length > 1) {
      setPasswordStrength(1)
    } else
      setPasswordStrength(0)*/
  }
  const onFinish = async (values) => {
    console.log('Success:', account);
  
    signInWithEmailAndPassword(auth,currentUser.email, oldPassword).then(()=>{
      dispatch(appActions.updateCompanyData(currentUser.uid, {
        uid: currentUser.uid,
        password
      })).then(
        (msg)=>{message.success(`Updated password successfully`)},
        (err)=>{message.error(err)}
      )
    }).catch((err)=>{
      message.error(err)
    })
    
  }
  return (
    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="intro-y box lg:mt-5">
          <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">
              Change Password
            </h2>
          </div>
          <div className="p-5">
            <div className="flex flex-col-reverse xl:flex-row flex-col">
              <div className="flex-1 mt-6 xl:mt-0">
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-12 mt-3">
                    <label htmlFor="update-profile-form-4" className="form-label">Old Password</label>
                    <Form.Item
                      name="password"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (oldPassword === account.password) {
                              return Promise.resolve();
                            }
                            return Promise.reject(<span>Old Password does not match.</span>);
                          },
                        }),
                      ]}
                    >
                      <input
                        onChange={(e) => setOldPassword(e.target.value)}
                        id="update-profile-form-6"
                        type="password"
                        className="form-control"
                        placeholder="Old Password"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 mt-3">
                    <label htmlFor="update-profile-form-4" className="form-label">New Password</label>
                    <Form.Item
                      name="newPassword"
                      rules={[{ required: true, message: 'New Password is required.' }]}
                    >
                      <input
                        onChange={strengthChecker}
                        id="update-profile-form-6"
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 mt-3">
                    <label htmlFor="update-profile-form-4" className="form-label">Confirm Password</label>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (password === confirmPassword) {
                              return Promise.resolve();
                            }
                            return Promise.reject(<span>Password does not match.</span>);
                          },
                        }),
                      ]}
                    >
                      <input
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="update-profile-form-6"
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary w-50 mt-3">Change Password</button>
              </div>
            </div>
          </div>
        
        </div>
      </Form>
    </div>
  
  )
}

export default ChangePassword
