import React, { useEffect } from "react";
import "./SuccessPage.css";
import successimage from "./SuccessImage.png";
import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../../../modules/auth/authSelectors";

function SuccessPage(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  useEffect(async () => {
    dispatch(appActions.updateCompanyData(currentUser.uid, {
      uid: currentUser.uid,
      currentPlan: props.match.params.id,
      planType: 'paid'
    }))
  }, []);
  
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white'
    }}>
      <img
        style={{ width: 400 }}
        src={successimage}
        alt="success"/>
      <h1
        style={{
          fontSize: 50,
          textAlign: 'center',
          color: 'rgb(2, 156, 2)'
        }}
      
      >Payment Successful</h1>
      <a
        style={{
          color: '#6c63ff',
          textDecoration: "none",
          marginTop: 100
        }}
        href={`/requests`}>
        <button
          className="btn btn-primary py-3 px-4 w-64 xl:mr-3 align-top">Go To Home
        </button>
      </a>
    </div>
  );
}

export default SuccessPage;
