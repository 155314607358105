import styled, {css} from "styled-components";
import {Tag as Chip} from "antd";

export const Container = styled.div`
  padding: 0 15px;
  min-height: 100%;
  margin: 0 auto;
  @media (min-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 980px;
  }
  @media (min-width: 1200px) {
    width: 1180px;
  }
  @media (min-width: 1600px) {
    width: 1580px;
  }
  @media (min-width: 1900px) {
    width: 1680px;
  }
  &.no-padding {
    padding: 0px;
  }
  .no-verticle-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const EG = styled.span`
   font-size: 12px !important;
`;
export const Label = styled.label`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  margin-bottom: 5px;
  display: block;
  color: #7c82a7 !important;
  span {
    color: #bfbfc3;
    margin-left: 5px;
  }
`;

export const Tag = styled(Chip)`
  padding: 6px 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
  ${(props) =>
  props.type === "primary" &&
  css`
      background: #4a60f4;
      border-radius: 20px;
      color: #fff;
    `}
  ${(props) =>
  props.type === "secoundary" &&
  css`
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      background: #ffecee;
      margin: 0 5px;
      color: #f20000;
    `}
`;
export const ImageWrapper = styled.div`
  display:flex;
  justify-content: center;
  .image { 
   position: relative;
  }
  span { 
   position: absolute; 
   top: 42%; 
   width: 100%;  
   text-align:center;
   color: #fff;
   font-size: 8px;
   font-family: Lato
 }`;
export const AppWrapper = styled.div`
  .ant-input-password,
  .input {
    font-size: 20px;
    height: 54px;
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 0;
  }
  .input-medium {
    font-size: 20px;
    height: 47.33px;
    margin-bottom: 0;
  }
  .transcription-row {
    
  }
  .transcription-row-highlight {
      background-color: #e3cdc1;
  }
`;
export const SpeakerWrapper = styled.div`
  display: flex;
  color: #707070;
  font-family: Lato;
  width: 50px;
  margin-left:10px;
  align-items:flex-start;
  justify-content: space-between;
  font-size: 10px;
  text-align: left;
  .speaker-select {
    width: 50px;
  }
`
export const ContentWrapper = styled.div`
  position: relative;
  padding: 0px;
  margin-top: 20px;
  .ant-table {
    padding: 10px 30px;
    overflow-x: auto;
    thead {
      tr {
        th {
          background: #fff;
          &:hover {
            background: #fff;
          }
        }
      }
      .ant-table-column-sorters:nth-child(1) {
        span {
          font-family: Lato;
          font-style: normal;
          font-weight: normal;
          // font-size: 16px;
          // line-height: 19px;
          color: #7c82a7;
        }
      }
    }
    .ant-table-cell {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 0px;
      color: #2c304b;
    }
    .country-flag {
      display: flex;
      align-items: center;
      .ant-image {
        padding-right: 10px;
      }
    }
    .status {
      display: flex;
      align-items: center;
      .ant-image {
        margin-right: 5px;
      }
    }
    .status-done {
      color: #57ad60;
    }
    .status-loading {
      color: #ee940c;
    }
    .status-error {
      color: #f05555;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #4f6ff4;
        border-color: #4f6ff4;
      }
    }
    .ant-table-column-sorters {
      span {
        white-space: pre;
      }
    }
  }
  .ant-pagination {
    justify-content: center;
    .ant-pagination-item-active {
      background: #4a60f4;
      border: none;
      a {
        color: #ffffff !important;
      }
    }
    .ant-pagination-item {
      a {
        color: #4a60f4;
      }
    }
    .ant-pagination-item-link {
      color: #4a60f4;
    }
    .ant-pagination-disabled {
      .ant-pagination-item-link {
        color: #d3d6eb !important;
      }
    }
  }
  .results {
    display: flex;
    position: absolute;
    bottom: 6px;
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #7c82a7;
    }
  }
  .no-display {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
