import React, { useState } from "react";
import { Checkbox, Form, message } from "antd";
import appActions from "../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../modules/auth/authSelectors";
import moment from "moment";
import { X } from "lucide-react";
import appSelectors from "../modules/app/appSelectors";
import axios from "axios";

function NewCoupon(props) {
  const {setShowModal} = props
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const onFinish = (values) => {
    console.log('Success:', values);
    setLoading(true)
    axios.post('https://us-central1-grabidentity.cloudfunctions.net/api/readme-token',{
      accountId: currentUser.uid,
      coupon: values.couponCode
    })
      .then((response)=>{
        setLoading(false)
        if(response.data.error){
          props.onFinish()
          message.error(response.data.message);
        }
        else{
          message.success(response.data.message);
        }
      }).catch((error) => {
        setLoading(false)
      console.log(error)
      message.error(error);
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <div style={{width: 600}}>
      <Form
        name="basic"
        labelCol={{span: 5}}
        wrapperCol={{span: 18}}
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="modal-content">
          <a data-tw-dismiss="modal" href="javascript:;">
            <X
              onClick={()=>setShowModal(false)}
              className="w-8 h-8 text-slate-400"/>
          </a>
          <div className="modal-header p-5">
            <h2 className="font-medium text-base mr-auto">Readme Coupon</h2>
          </div>
          <div className="modal-body">
      
            <div className="">
              <div className="mt-3">
                <label htmlFor="regular-form-3" className="form-label">Coupon Code</label>
                <Form.Item
                  name="couponCode"
                  rules={[{required: true, message: 'Please enter coupon code!'}]}
                >
                <input id="regular-form-3" type="text" className="form-control" placeholder=""/>
                </Form.Item>
              </div>
              <div className="w-full sm:w-auto flex mt-4 sm:mt-5 ">
                <button disabled={loading} className="btn btn-primary shadow-md mr-2 w-26">Readme</button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default NewCoupon
