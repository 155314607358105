import React from 'react';
import {Route} from "react-router-dom";

function SimpleRoute({
  component: Component,
  currentUser,
  permissionRequired,
  key,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Component {...props} />
        );
      }}
    />
  );
}

export default SimpleRoute;
