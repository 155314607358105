import React, { useState } from "react";
import { Form, message, notification } from "antd";
import { useHistory } from "react-router-dom";
import { Spacer, } from "./styles";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, } from "firebase/auth";
import Button from "../../../components/Button";
//import {Auth} from "aws-amplify";
import { useDispatch } from "react-redux";
//import authActions from "../../../modules/auth/authActions";
import { auth } from "../../../firebase";
import appActions from "../../../modules/app/appActions";

const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const onFinish = async (values) => {
    const { email, password } = values;
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        if(user.emailVerified) {
          history.push('/requests')
        }else {
          notification.error({
            message: 'Unverified',
            description:
              'Your account is not verified. Please check your inbox/junk folder.',
          });
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log('errorMessage', errorMessage)
        if(errorMessage.includes('auth/wrong-password'))
          message.error('Authentication error. Invalid username/password or both');
        else if(errorMessage.includes('auth/user-not-found')){
          message.error('Authentication error. Invalid username/password or both');
        }
        else
          message.error(errorMessage);
      });
  };
  const handleGoogleSignin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user;
          user.source = 'google';
          dispatch(appActions.createAccount(user))
          history.push('/requests')
        }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <div className="login" style={{marginRight: '-2rem', marginLeft: '-2rem'}}>
      <div className="container sm:px-10">
        <div className="block xl:grid grid-cols-2 gap-4">
          <div className="hidden xl:flex flex-col min-h-screen">
            <a href="" className="-intro-x flex items-center pt-5">
              <img
                alt="Midone - HTML Admin Template"
                className="w-6"
                src={require('./../../../assets/images/logo.svg').default}
              />
              <span className="text-white text-lg ml-3"> Idenfication </span>
            </a>
            <div className="my-auto">
              <img alt="Midone - HTML Admin Template" className="-intro-x w-1/2 -mt-16"
                   src={require('./../../../assets/images/illustration.svg').default}/>
              <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                A few more clicks to
                <br/>
                sign in to your account.
              </div>
              <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
              </div>
            </div>
          </div>
          
          <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
            <div
              className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                Sign In
              </h2>
              <div className="intro-x mt-2 text-slate-400 xl:hidden text-center">A few more clicks to sign in to your
                account.
              </div>
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
              >
                <div className="intro-x mt-8">
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                  >
                    <input type="text" className="intro-x login__input form-control py-3 px-4 block"
                           placeholder="Email"/>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Password is required' }]}
                  >
                    <input type="password" className="intro-x login__input form-control py-3 px-4 block mt-4"
                           placeholder="Password"/>
                  </Form.Item>
                </div>
                <div className="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4">
                  <div className="flex items-center mr-auto">
                    <input id="remember-me" type="checkbox" className="form-check-input border mr-2"/>
                    <label className="cursor-pointer select-none" htmlFor="remember-me">Remember me</label>
                  </div>
                  <a href="">Forgot Password?</a>
                </div>
                <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                  <button
                    type={'submit'}
                    className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">Login
                  </button>
                  <button
                    onClick={() => {
                      history.push('/signup')
                    }}
                    className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">Register
                  </button>
                </div>
              </Form>
              <Spacer>
                <p>or</p>
              </Spacer>
              <Button
                handleClick={handleGoogleSignin}
                className="google__btn btn btn-outline-primary"
                veriant="outline"
                size="medium"
                block
              >
                Log in with Google
              </Button>
              <div className="intro-x mt-10 xl:mt-24 text-slate-600 dark:text-slate-500 text-center xl:text-left"> By
                signin up, you agree to our <a className="text-primary dark:text-slate-200"
                                               target="_blank"
                                               href="https://grabidentity.com/terms-and-conditions">Terms and
                  Conditions</a> & <a className="text-primary dark:text-slate-200"
                                      target="_blank"
                                      href="https://grabidentity.com/privacy">Privacy Policy</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
