import React, { useEffect, useState } from "react";
import { X } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import authSelectors from "../../modules/auth/authSelectors";
import { Form, message, Upload } from "antd";
import uuid from "uuid-v4";
import { ref, storage } from "../../firebase";
import { getDownloadURL, uploadBytes } from "firebase/storage";

function Branding() {
  const dispatch = useDispatch();
  const account = useSelector(appSelectors.getAccount);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const [pictureUrl, setPictureUrl] = useState();
  const [form] = Form.useForm()
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCompanyData(currentUser.uid))
    }
  }, [currentUser])
  useEffect(() => {
    if (account.companyLogo) {
      setPictureUrl(account.companyLogo)
    }
    form.setFieldsValue({
      website: account.website ? account.website : '',
      companyName: account.companyName ? account.companyName : '',
      signature: account.signature ? account.signature : ''
    })
  }, [account])
  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(appActions.updateCompanyData(currentUser.uid, {
      uid: currentUser.uid,
      companyLogo: pictureUrl ? pictureUrl : '',
      ...values
    })).then(
      (msg) => {
        message.success(msg)
      },
      (err) => {
        message.error(err)
      }
    )
  }
  const uploadProps = {
    maxCount: 1,
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    
    async customRequest({ onError, onSuccess, file, filename, data }) {
      const token = uuid()
      const metadata = {
        contentType: 'image/jpeg',
        metadata: {
          firebaseStorageDownloadTokens: token, // Can technically be anything you want
        },
      }
      const storageRef = ref(storage, `${token}.jpg`);
      try {
        console.log('uploadBytes')
        uploadBytes(storageRef, file, metadata).then((snapshot) => {
          getDownloadURL(storageRef)
            .then((url) => {
              console.log('url', url)
              setPictureUrl(url)
              onSuccess(null, snapshot);
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log('error', error)
            });
        });
      } catch (e) {
        onError(e);
      }
    }
  };
  
  
  return (
    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
      <Form
        form={form}
        name="basic"
        initialValues={{ website: '', signature: '', companyName: '' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="intro-y box lg:mt-5">
          <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">
              Branding
            </h2>
          </div>
          <div className="p-5">
            <div className="flex flex-col-reverse xl:flex-row flex-col">
              <div className="flex-1 mt-6 xl:mt-0">
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-12 2xl:col-span-6">
                    <div>
                      <label htmlFor="update-profile-form-1" className="form-label">Company Name</label>
                      <Form.Item
                        name="companyName"
                        rules={[{ required: true, message: 'Company name is required!' }]}
                      >
                        <input
                          id="update-profile-form-1"
                          type="text"
                          className="form-control"
                          placeholder="Your company name."
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-span-12 mt-3">
                    <label htmlFor="update-profile-form-4" className="form-label">Company Website</label>
                    <Form.Item
                      name="website"
                      rules={[{ required: false }]}
                    >
                      <input
                        id="update-profile-form-6"
                        type="text"
                        className="form-control"
                        placeholder="Web Link"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-span-12 mt-3">
                    <label htmlFor="update-profile-form-7" className="form-label">Email Signature</label>
                    <Form.Item
                      name="signature"
                      rules={[{ required: false, message: 'signature is required!' }]}
                    >
                      <textarea className="form-control" rows="4"/>
                    </Form.Item>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary w-20 mt-3">Save</button>
              </div>
              <div className="w-52 mx-auto xl:mr-0 xl:ml-6">
                <div
                  className="border-2 border-dashed shadow-sm border-slate-200/60 dark:border-darkmode-400 rounded-md p-5">
                  <div className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                    <img
                      className="rounded-md"
                      alt=""
                      src={pictureUrl ? pictureUrl : require("./../../assets/images/profile-6.jpg") }
                    />
                    {pictureUrl &&<div title="Remove this profile photo?"
                         className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-danger right-0 top-0 -mr-2 -mt-2">
                      <X className="w-4 h-4"/>
                    </div>}
                  </div>
                  <div className="w-50 cursor-pointer relative mt-5">
                    <Upload {...uploadProps} >
                      <button type="button" className="btn btn-primary w-full">Change Photo</button>
                    </Upload>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  
  )
}

export default Branding
