import { db } from "../../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  orderBy
} from "firebase/firestore";
import axios from "axios";
import bcrypt from 'bcryptjs'

const prefix = 'APP';
const snooze = ms => new Promise(resolve => setTimeout(resolve, ms));
const appActions = {
  LOADING: `${prefix}_LOADING`,
  GET_REQUESTS: `${prefix}_GET_REQUESTS`,
  GET_COUPONS: `${prefix}_GET_COUPONS`,
  GET_BILLINGS: `${prefix}_GET_BILLINGS`,
  GET_ACCOUNT: `${prefix}_GET_ACCOUNT`,
  GET_APP_SETTINGS: `${prefix}_GET_APP_SETTINGS`,
  
  deleteRequests: (id) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        //await deleteDoc(doc(db, "requests", id));
        const docRef = doc(db, "requests", id);
        const data = {status:'deleted'};
        await updateDoc(docRef, data);
        resolve(true)
      } catch (e) {
        reject(e)
      }
    })
  },
  sendEmail: (email, link, name, company_name, company_website, signature) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await axios.post(`https://us-central1-grabidentity.cloudfunctions.net/api/send-email`, {
          to: email,
          link,
          name,
          company_name,
          company_website,
          signature
        })
        resolve(resp.data)
      } catch (e) {
        reject(e)
      }
    })
  },
  getRequests: (uid) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const q = query(
          collection(db, "requests"),
          where("accountId", "==", uid),
          where("status", "!=", 'deleted'),
          orderBy('status', 'desc'),
          orderBy('updatedDate', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const requests = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          requests.push({ id: doc.id, ...doc.data() })
        });
        console.log('requests 11', requests)
        dispatch({
          type: appActions.GET_REQUESTS,
          payload: {
            requests
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  getCoupons: (uid) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const q = query(
          collection(db, "coupons"),
          where("accountId", "==", uid),
          orderBy('usedAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const coupons = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          coupons.push({ id: doc.id, ...doc.data() })
        });
  
        const reqs = query(
          collection(db, "requests"),
          where("accountId", "==", uid)
        );
        const reqsSnapshot = await getDocs(reqs);
        
        console.log('coupons', coupons, reqsSnapshot.docs.length)
        dispatch({
          type: appActions.GET_COUPONS,
          payload: {
            coupons,
            usedRequests: reqsSnapshot.docs.length
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  getBillings: (email) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const q = query(
          collection(db, "payments"),
          where("email", "==", email)
        );
        const querySnapshot = await getDocs(q);
        const billings = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          billings.push({ id: doc.id, ...doc.data() })
        });
        console.log('email', email, billings)
        dispatch({
          type: appActions.GET_BILLINGS,
          payload: {
            billings
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  createAccount: (user) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const accountsRef = collection(db, "accounts");
        const docRef = doc(db, "accounts", user.uid);
        const docSnap = await getDoc(docRef);
        const hash = user.password ? bcrypt.hashSync(user.password, "$2a$10$rfdt/RtSzB56yZw9R.ULBe") : "";
        if (!docSnap.exists()) {
          await setDoc(doc(accountsRef, user.uid), {
            email: user.email,
            displayName: user.displayName ? user.displayName : '',
            photoURL: user.photoURL ? user.photoURL : '',
            uid: user.uid,
            firstName: user.firstName ? user.firstName :'',
            lastName: user.lastName ? user.lastName : '',
            password: hash,
            reqAllowed: 10,
            source: user.source,
            createdAt: user.metadata && user.metadata.createdAt ?  user.metadata.createdAt : ''
          })
        }
      } catch (e) {
        console.log(e)
      }
    })
  },
  createNewRequest: (request) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestsRef = collection(db, "requests");
        const doc = await addDoc(requestsRef, request)
        resolve(doc.id)
      } catch (e) {
        console.log(e)
        reject(e)
      }
    })
  },
  searchRequests: (uid, text) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const q = query(collection(db, "requests"), where("accountId", "==", uid));
        const querySnapshot = await getDocs(q);
        const requests = []
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          requests.push({ id: doc.id, ...doc.data() })
        });
        console.log(requests)
        const data = requests.filter((req) =>
          `${req.firstName} ${req.lastName}`.toLowerCase().includes(text)
        );
        dispatch({
          type: appActions.GET_REQUESTS,
          payload: {
            requests: data
          }
        })
      } catch (e) {
        reject(e)
      }
    })
  },
  getCompanyData: (uid) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(db, "accounts", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data()
          const account = { ...data, id: uid }
          console.log("Document data:", data);
          dispatch({
            type: appActions.GET_ACCOUNT,
            payload: {
              account
            }
          })
        } else {
          dispatch({
            type: appActions.GET_ACCOUNT,
            payload: {
              account: {}
            }
          })
        }
        
      } catch (e) {
        reject(e)
      }
    })
  },
  updateCompanyData: (uid, data) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: true
          }
        })
        const docRef = doc(db, "accounts", uid);
        await updateDoc(docRef, data);
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: false
          }
        })
        resolve(`Updated successfully`)
      } catch (e) {
        reject(e)
      }
    })
  },
  updateKey: (uid, key) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: true
          }
        })
        const docRef = doc(db, "accounts", uid);
        const last4 = '*********************'+key.slice(-4);
        await updateDoc(docRef, { key: last4 });
        const keysDocRef = doc(db, "keys", uid);
        await setDoc(keysDocRef, {key})
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: false
          }
        })
        resolve(`Updated successfully`)
      } catch (e) {
        reject(e)
      }
    })
  },
  getAppSettings: () => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(db, "settings", 'app-settings');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data()
          dispatch({
            type: appActions.GET_APP_SETTINGS,
            payload: {
              settings: data
            }
          })
        }
        
      } catch (e) {
        reject(e)
      }
    })
  },
};

export default appActions;
