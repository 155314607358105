import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import authSelectors from "../../modules/auth/authSelectors";
import moment from "moment";

function Billing() {
  const dispatch = useDispatch();
  const billings = useSelector(appSelectors.selectUserBillings);
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getBillings(currentUser.email))
    }
  }, [currentUser])
  
  return (
    <div className="col-span-12 lg:col-span-8 2xl:col-span-9 mt-5">
      <div className="intro-y box">
        <div className="overflow-x-auto scrollbar-hidden">
          <div id="tabulatorTMP" className="table-report table-report--tabulator"></div>
    
          <div id="tabulator234" className="table-report table-report--tabulator tabulator" role="grid">
            <div className="tabulator-header" style={{ paddingRight: 0, marginLeft: 0 }}>
              <div className="tabulator-headers" style={{ marginLeft: 0 }}>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 200, height: 44, width: '30%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Email</div>
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '20%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Amount</div>
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '25%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Charge Date</div>
                    </div>
                  </div>
                </div>
                <div className="tabulator-col tabulator-sortable" role="columnheader" aria-sort="none" title=""
                     style={{ minWidth: 100, height: 44, width: '25%' }}>
                  <div className="tabulator-col-content">
                    <div className="tabulator-col-title-holder">
                      <div className="tabulator-col-title">Status</div>
              
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabulator-frozen-rows-holder"></div>
            </div>
      
      
            <div className="tabulator-tableHolder" tabIndex="0" style={{ height: '60vh' }}>
              <div className="tabulator-table" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                {billings && billings.map((request) =>
                  <div className="tabulator-row tabulator-selectable tabulator-row-odd" role="row"
                       style={{ paddingLeft: 0 }}>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{ width: '30%', display: 'inline-flex', alignItems: 'center', height: 64 }}>
                      <div>
                        <div className="font-medium whitespace-nowrap">{`${request.email}`}</div>
                      </div>
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "20%",
                           textAlign: 'left',
                           display: 'inline-flex',
                           alignItems: 'center',
                           justifyContent: 'left',
                           height: 64
                         }}>
                      {Number.parseFloat(request.amount/100).toFixed(2)} {request.currency.toUpperCase()}
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "25%",
                           textAlign: "center",
                           display: 'inline-flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           height: 64
                         }}>
                      {request.created ? moment.unix(request.created).format('DD/MM/YYYY hh:mm A') : ''}
                    </div>
                    <div className="tabulator-cell" role="gridcell" title=""
                         style={{
                           width: "25%",
                           textAlign: "center",
                           display: "inline-flex",
                           alignItems: "center",
                           justifyContent: "center",
                           height: 64
                         }}>
                      <span
                        style={{
                          color: "#fff",
                          textDecoration: "none",
                          backgroundColor: "#117a8b",
                          padding: "3px 8px 3px 8px",
                          borderRadius: 15,
                          width: 105
                        }}
                        className="bg-warning tooltip">{request.status ? request.status.toUpperCase() : ''}</span>
                    </div>
                  </div>
                )
                }
              </div>
      
            </div>
    
          </div>
        </div>
      </div>
    </div>
  
  )
}

export default Billing
