import React, { useEffect, useRef } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import SimpleRoute from './../../modules/routes/SimpleRoute';
import PrivateRoute from './../../modules/routes/PrivateRoute';
import authSelectors from './../../modules/auth/authSelectors';
import Login from "../../containers/Auth/Login";
import Signup from "../../containers/Auth/Signup";

import Dashboard from "../../containers/Dashboard/Requests";

import AppSumo from "../../containers/Packages/AppSumo";
import Billing from "../../containers/Dashboard/Billing";
import AccountInfo from "../../containers/Dashboard/Account";
import Packages from "../../containers/Packages";
import Settings from "../../containers/Settings";

import { useSelector } from "react-redux";
import ProgressBar from "../Shared/ProgressBar";
import Layout from "../../Layout";
import SuccessPage from "../Paywall/SuccessPage/SuccessPage";
import FailurePage from "../Paywall/FailurePage/FailurePage";

function RoutesComponent(props) {

  const withSidebar = (key, Comp, props = {}) => {
    return (
      <Layout slot={key}>
        <Comp {...props}/>
      </Layout>
    );
  };

  const isInitialMount = useRef(true);

  const loading = false

  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const authLoading = useSelector(
    authSelectors.selectLoading,
  );

  useEffect(() => {
    //ProgressBar.start();

    if (!authLoading) {
      ProgressBar.done();
    } else {
      ProgressBar.start();
    }
  }, [authLoading]);
  if(authLoading)
    return <div/>

  return (
    <Switch>
      <Redirect exact from="/" to="/requests"/>
      <SimpleRoute
        exact
        path="/login"
        key={"login"}
        currentUser={currentUser}
        component={(props) => <Login {...props}/>}
      />
      <SimpleRoute
        exact
        path="/signup"
        key={"/signup"}
        currentUser={currentUser}
        component={(props) => <Signup {...props}/>}
      />
      <PrivateRoute
        path="/requests"
        key={"requests"}
        currentUser={currentUser}
        permissionRequired={{}}
        component={(props) => withSidebar("requests", Dashboard)}
      />
      <PrivateRoute
        path="/settings/packages"
        key={"packages"}
        currentUser={currentUser}
        permissionRequired={{}}
        component={(props) => withSidebar("packages", AppSumo)}
      />
      <PrivateRoute
        path="/settings"
        key={"settings"}
        currentUser={currentUser}
        permissionRequired={{}}
        component={(props) => withSidebar("settings", Settings)}
      />
      <PrivateRoute
        path="/dashboard/account-info"
        key={"dashboard/account-info"}
        currentUser={currentUser}
        permissionRequired={{}}
        component={() => withSidebar("account", AccountInfo)}
      />
      <PrivateRoute
        path="/settings"
        key={"settings"}
        currentUser={currentUser}
        permissionRequired={{}}
        component={() => withSidebar("settings", Billing)}
      />
      <PrivateRoute
        exact
        path="/success"
        key={"/success"}
        currentUser={currentUser}
        component={(props) => <SuccessPage {...props} />}
      />
      <PrivateRoute
        exact
        path="/success/:id"
        key={"/successId"}
        currentUser={currentUser}
        component={(props) => <SuccessPage {...props} />}
      />
      <PrivateRoute
        exact
        path="/failed"
        key={"/failed"}
        currentUser={currentUser}
        component={(props) => <FailurePage {...props} />}
      />
      <PrivateRoute
        exact
        path="/app-sumo"
        key={"/failed"}
        currentUser={currentUser}
        component={(props) => withSidebar("packages", AppSumo)}
      />
    </Switch>
  );
}

export default RoutesComponent;
