import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyBc0Mun-k-QTr7pqtBwOzkRTXjdA5GcAD4",
  authDomain: "grabidentity.firebaseapp.com",
  projectId: "grabidentity",
  storageBucket: "grabidentity.appspot.com",
  messagingSenderId: "100821579126",
  appId: "1:100821579126:web:ee75c0fc3df01697a5f657"
};
const firebase = initializeApp(firebaseConfig);

const auth = getAuth(firebase);
const storage = getStorage();
const db = getFirestore(firebase)
export {firebase, auth, storage, db, ref};
