import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../../modules/auth/authSelectors";
import { loadStripe } from "@stripe/stripe-js";
import { PRICES, STRIPE_API_KEY } from "../../Constants";
import appSelectors from "../../modules/app/appSelectors";
import appActions from "../../modules/app/appActions";
import { Button } from "antd";
import NewCoupon from "../../components/NewCoupon";
import moment from "moment";

function AppSumo() {
  const dispatch = useDispatch()
  const [paymentType, setPaymentType] = useState('monthly')
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  const account = useSelector(appSelectors.getAccount);
  const requests = useSelector(appSelectors.selectUsedRequests);
  const coupons = useSelector(appSelectors.selectUsedCoupons);
  
  const [loading, setLoading] = useState(false);
  
  
  useEffect(() => {
    if (currentUser) {
      dispatch(appActions.getCoupons(currentUser.uid))
    }
  }, [currentUser])
  return (
    <>
      <div id="layout-wrapper">
        <div className="main-content">
      
          <div className="page-content">
            <div className="container-fluid">
          
              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-height-100 box p-5">
                    <div className="card-body">
                      <h5 className="card-title fw-semibold mb-3">Verification Requests</h5>
                      <div className="progress animated-progress custom-progress mb-1">
                        <div className="progress-bar bg-secondary" role="progressbar"
                             style={{width: "38%"}}
                             aria-valuenow="38" aria-valuemin="0" aria-valuemax="100"/>
                      </div>
                      <p className="text-muted mb-2">{`You used ${requests} of ${account.reqAllowed} of your Requests`}</p>
                      <div className="text-end" style={{textAlign:'right'}}>
                        <Button
                          onClick={()=>{
                            document.getElementById('button-slide-over-preview').classList.toggle('show')
                          }}
                          type="button" className="btn btn-success btn-sm create-btn"
                                data-bs-toggle="modal" data-bs-target="#api-key-modal">Readme Coupon
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
          
              </div>
          
              <div className="row mt-10">
                <div className="col-lg-12">
                  <div className="card box" id="apiKeyList">
                    <div className="card-header flex items-center justify-between p-5">
                      <h2 className="">Token History</h2>
                      <div className="d-flex gap-1 flex-wrap">
                        <div className="text-end" style={{textAlign:'right'}}>
                        
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div>
                        <div className="table-responsive table-card mb-3">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr>
                              <th className="sort" data-sort="token" scope="col">Token</th>
                              <th className="sort" data-sort="requests" scope="col">Api Requests</th>
                              <th className="sort" data-sort="used" scope="col">Used At</th>
                            </tr>
                            </thead>
                            <tbody className="list form-check-all">
                            {
                              coupons?.map((coupon)=>(
                                <tr>
                                  <td className="apikey">
                                    <input type="text" className="form-control apikey-value" readOnly
                                           value={coupon.coupon}/>
                                  </td>
                                  <td className="requests">{100}</td>
                                  <td className="used">{moment(coupon.usedAt).format("MM/DD/YYYY hh:mm a")}</td>
                                </tr>
                              ))
                            }
                            </tbody>
                          </table>
                          <div className="noresult" style={{display: "none"}}>
                            <div className="text-center">
                              <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                         colors="primary:#121331,secondary:#08a88a"
                                         style={{width:75, height:75}}/>
                              <h5 className="mt-2">Sorry! No Result Found</h5>
                              <p className="text-muted mb-0">You have readme any token.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="modal fade" id="api-key-modal" tabIndex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Create API Key</h5>
                  <button type="button" className="btn-close" id="close-modal" data-bs-dismiss="modal"
                          aria-label="Close"/>
                </div>
                <div className="modal-body">
                  <form autoComplete="off">
                    <div id="api-key-error-msg" className="alert alert-danger py-2 d-none"/>
                    <input type="hidden" id="apikeyId"/>
                    <div className="mb-3">
                      <label htmlFor="api-key-name" className="form-label">API Key Name <span
                        className="text-danger">*</span></label>
                      <input type="text" className="form-control" id="api-key-name"
                             placeholder="Enter api key name"/>
                    </div>
                    <div className="mb-3" id="apikey-element">
                      <label htmlFor="api-key" className="form-label">API Key</label>
                      <input
                        type="text"
                        className="form-control" id="api-key"
                        disabled={true}
                        value="b5815DE8A7224438932eb296Z5"/>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <div className="hstack gap-2 justify-content-end">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" id="createApi-btn">Create API</button>
                    <button type="button" className="btn btn-primary" id="add-btn">Add</button>
                    <button type="button" className="btn btn-primary" id="edit-btn">Save Changes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade zoomIn" id="deleteApiKeyModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                          id="deleteRecord-close"/>
                </div>
                <div className="modal-body">
                  <div className="mt-2 text-center">
                    <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop"
                               colors="primary:#f7b84b,secondary:#f06548"
                               style={{width:100,height:100}}/>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                      <h4>Are you Sure ?</h4>
                      <p className="text-muted mx-4 mb-0">Are you Sure You want to Remove this API Key ?</p>
                    </div>
                  </div>
                  <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn w-sm btn-danger " id="delete-record">Yes, Delete It!
                    </button>
                  </div>
                </div>
          
              </div>
            </div>
          </div>
    
        </div>
  
      </div>
      
  
      <div className="customizer-setting d-none d-md-block">
        <div className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
             data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
          <i className='mdi mdi-spin mdi-cog-outline fs-22'/>
        </div>
      </div>
  
      <div
        id="button-slide-over-preview"
        className="modal modal-slide-over overflow-y-auto"
        data-tw-backdrop="static" tabIndex="-1"
        aria-hidden="true"
        style={{
          marginTop: 0,
          marginLeft: 0,
          paddingLeft: 0,
          zIndex: 10000
        }}
      >
        <div className="modal-dialog">
          <NewCoupon
            onFinish={() => {
              document.getElementById('button-slide-over-preview').classList.toggle('show')
              dispatch(appActions.getCoupons(currentUser.uid))
            }}
            setShowModal={() => {
              document.getElementById('button-slide-over-preview').classList.toggle('show')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default AppSumo
